exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-docs-expressive-bidding-guide-mdx": () => import("./../../../src/pages/docs/expressive/bidding-guide.mdx" /* webpackChunkName: "component---src-pages-docs-expressive-bidding-guide-mdx" */),
  "component---src-pages-docs-expressive-faq-mdx": () => import("./../../../src/pages/docs/expressive/faq.mdx" /* webpackChunkName: "component---src-pages-docs-expressive-faq-mdx" */),
  "component---src-pages-docs-expressive-index-mdx": () => import("./../../../src/pages/docs/expressive/index.mdx" /* webpackChunkName: "component---src-pages-docs-expressive-index-mdx" */),
  "component---src-pages-docs-expressive-reference-mdx": () => import("./../../../src/pages/docs/expressive/reference.mdx" /* webpackChunkName: "component---src-pages-docs-expressive-reference-mdx" */),
  "component---src-pages-docs-expressive-start-here-mdx": () => import("./../../../src/pages/docs/expressive/start-here.mdx" /* webpackChunkName: "component---src-pages-docs-expressive-start-here-mdx" */),
  "component---src-pages-docs-fix-fix-42-index-mdx": () => import("./../../../src/pages/docs/fix/fix-42/index.mdx" /* webpackChunkName: "component---src-pages-docs-fix-fix-42-index-mdx" */),
  "component---src-pages-docs-fix-primer-mdx": () => import("./../../../src/pages/docs/fix/primer.mdx" /* webpackChunkName: "component---src-pages-docs-fix-primer-mdx" */),
  "component---src-pages-docs-index-tsx": () => import("./../../../src/pages/docs/index.tsx" /* webpackChunkName: "component---src-pages-docs-index-tsx" */),
  "component---src-pages-docs-market-structure-clearing-examples-mdx": () => import("./../../../src/pages/docs/market-structure/clearing-examples.mdx" /* webpackChunkName: "component---src-pages-docs-market-structure-clearing-examples-mdx" */),
  "component---src-pages-docs-symbology-mdx": () => import("./../../../src/pages/docs/symbology.mdx" /* webpackChunkName: "component---src-pages-docs-symbology-mdx" */),
  "component---src-pages-docs-templates-etf-mdx": () => import("./../../../src/pages/docs/templates/etf.mdx" /* webpackChunkName: "component---src-pages-docs-templates-etf-mdx" */),
  "component---src-pages-docs-templates-hedging-mdx": () => import("./../../../src/pages/docs/templates/hedging.mdx" /* webpackChunkName: "component---src-pages-docs-templates-hedging-mdx" */),
  "component---src-pages-docs-templates-index-mdx": () => import("./../../../src/pages/docs/templates/index.mdx" /* webpackChunkName: "component---src-pages-docs-templates-index-mdx" */),
  "component---src-pages-docs-templates-indifference-curves-mdx": () => import("./../../../src/pages/docs/templates/indifference-curves.mdx" /* webpackChunkName: "component---src-pages-docs-templates-indifference-curves-mdx" */),
  "component---src-pages-docs-templates-pairs-mdx": () => import("./../../../src/pages/docs/templates/pairs.mdx" /* webpackChunkName: "component---src-pages-docs-templates-pairs-mdx" */),
  "component---src-pages-docs-templates-portfolios-mdx": () => import("./../../../src/pages/docs/templates/portfolios.mdx" /* webpackChunkName: "component---src-pages-docs-templates-portfolios-mdx" */),
  "component---src-pages-docs-templates-single-stock-mdx": () => import("./../../../src/pages/docs/templates/single-stock.mdx" /* webpackChunkName: "component---src-pages-docs-templates-single-stock-mdx" */),
  "component---src-pages-docs-tutorials-index-tsx": () => import("./../../../src/pages/docs/tutorials/index.tsx" /* webpackChunkName: "component---src-pages-docs-tutorials-index-tsx" */),
  "component---src-pages-docs-tutorials-pairs-mdx": () => import("./../../../src/pages/docs/tutorials/pairs.mdx" /* webpackChunkName: "component---src-pages-docs-tutorials-pairs-mdx" */),
  "component---src-pages-docs-tutorials-peg-mdx": () => import("./../../../src/pages/docs/tutorials/peg.mdx" /* webpackChunkName: "component---src-pages-docs-tutorials-peg-mdx" */),
  "component---src-pages-docs-tutorials-price-curve-mdx": () => import("./../../../src/pages/docs/tutorials/price-curve.mdx" /* webpackChunkName: "component---src-pages-docs-tutorials-price-curve-mdx" */),
  "component---src-pages-docs-user-manual-mdx": () => import("./../../../src/pages/docs/user-manual.mdx" /* webpackChunkName: "component---src-pages-docs-user-manual-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-bcp-mdx": () => import("./../../../src/pages/legal/bcp.mdx" /* webpackChunkName: "component---src-pages-legal-bcp-mdx" */),
  "component---src-pages-legal-cookie-policy-mdx": () => import("./../../../src/pages/legal/cookie-policy.mdx" /* webpackChunkName: "component---src-pages-legal-cookie-policy-mdx" */),
  "component---src-pages-legal-privacy-policy-mdx": () => import("./../../../src/pages/legal/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-mdx" */),
  "component---src-pages-legal-regulatory-mdx": () => import("./../../../src/pages/legal/regulatory.mdx" /* webpackChunkName: "component---src-pages-legal-regulatory-mdx" */),
  "component---src-pages-legal-terms-of-use-mdx": () => import("./../../../src/pages/legal/terms-of-use.mdx" /* webpackChunkName: "component---src-pages-legal-terms-of-use-mdx" */),
  "component---src-pages-products-conditional-mdx": () => import("./../../../src/pages/products/conditional.mdx" /* webpackChunkName: "component---src-pages-products-conditional-mdx" */),
  "component---src-pages-products-nexus-mdx": () => import("./../../../src/pages/products/nexus.mdx" /* webpackChunkName: "component---src-pages-products-nexus-mdx" */)
}

